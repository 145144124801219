<template>
  <section class="avaliacao-view">
    <v-row>
      <v-col cols="12" md="4" lg="3">
        <v-card>
          <v-card-title>Dados do cliente</v-card-title>
          <v-card-text></v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <v-tabs show-arrows center-active v-model="tabSelected[0]">
          <v-tab
            v-for="(tab, index) in tabsAvaliacaoList"
            :disabled="tab.disabled"
            :key="index"
            :href="`#tab-${index}`"
            style="color: #5c6da7"
          >
            {{ tab.name }}
          </v-tab>
          <v-tab-item
            v-for="(tab, index) in tabsAvaliacaoList"
            :key="index"
            :value="'tab-' + index"
          >
            <component :ref="tab.ref" :is="tab.component"></component>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </section>
</template>
  <script>
import store from "@/store";
import {
  onUnmounted,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  watch,
  onUpdated,
} from "@vue/composition-api";

export default {
  name: "Avaliacao-View",
  components: {},
  setup() {
    /* TODO
        - Receber informações da avaliação
        - Receber informações do cliente
    */
    onMounted(() => {});
    const tabsAvaliacaoList = computed(
      () => store.getters["avd/getTabsVisualizarConfiguracaoAvaliacao"]
    );
    const tabSelected = computed(
      () => store.getters["avd/getTabPainel"]
    );

    const tabs = ref("");
    return {
      tabsAvaliacaoList,
      tabs,
      tabSelected
    };
  },
};
</script>
  
<style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
</style>