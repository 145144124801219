import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"avaliacao-view"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4","lg":"3"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Dados do cliente")]),_c(VCardText)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"8","lg":"9"}},[_c(VTabs,{attrs:{"show-arrows":"","center-active":""},model:{value:(_vm.tabSelected[0]),callback:function ($$v) {_vm.$set(_vm.tabSelected, 0, $$v)},expression:"tabSelected[0]"}},[_vm._l((_vm.tabsAvaliacaoList),function(tab,index){return _c(VTab,{key:index,staticStyle:{"color":"#5c6da7"},attrs:{"disabled":tab.disabled,"href":("#tab-" + index)}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),_vm._l((_vm.tabsAvaliacaoList),function(tab,index){return _c(VTabItem,{key:index,attrs:{"value":'tab-' + index}},[_c(tab.component,{ref:tab.ref,refInFor:true,tag:"component"})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }